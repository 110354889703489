
  

.productscurrent{
    background-color: rgba(255, 255, 255, 0.097);
}

.productscurrent img {
    transition: opacity 0.5s ease-in-out;
  }
  
  .transition-opacity {
    transition: opacity 0.5s ease-in-out;
  }





  /* .inactive {
    filter: grayscale(100%);
    scale: 50%;
}
.active{
    filter: grayscale(0%);
    scale: 100%;
    transition: filter 0.3s ease, scale 0.3s ease; /* Smooth transition for property changes */

/* } */ 
.fade-in-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.text-transition {
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
