/* 
.webbg1{
    background-image: url('../../assets/images/web (1).png');
    background-size: cover;
    box-shadow:#D3A000 0px 22px 70px 4px;
} */
/* .webbg1{
    background-image: url('../../assets/images/web (2).png');
    background-size: cover;
    box-shadow: #E98B46 0px 22px 70px 4px;
} */
.webbg1{
    /* background-image: url('../../assets/images/web (3).png'); */
    background-size: cover;
    background-position: center;
    box-shadow: #8F85E0 0px 22px 70px 4px;
}
.webbg2{
    background-image: url('../../assets/images/web (4).png');
    background-size: cover;
    background-position: center;

    box-shadow: #8F85E0 0px 22px 70px 4px;
}
.webbg3{
    background-image: url('../../assets/images/web (5).png');
    background-size: cover;
    background-position: center;

    box-shadow: #1A91FF 0px 22px 70px 4px;
}

.blueshadow{
    box-shadow: #0084ff 0px 22px 70px 4px;
}

#webscreen{
    transition: all ease-in-out 3s;

}



.noisebg2{
    /* background:  url('../../assets/images/013.jpg'); */
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/028.png');
    
  }
  
  
  