

.game{
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  #201317 0%, #0D0B16 90% );
    

}






@keyframes wave {
    0% {
      transform:  translateY(0);
    }
    25% {
      transform: translateY(5px);
    }
    50%{
        transform: translateY(0);
    }
    75% {
      transform:  translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }

.char {
    filter: drop-shadow(-20px 20px 20px #000000b1);
    animation: wave 8s ease-in-out infinite;

}




#twin1 {
    display: flex;
    flex-direction: row;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}


#twin2 {
    display: flex;
    flex-direction: row;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}


.gamebody{
background: radial-gradient(circle, rgba(213,255,255,1) 0%, rgba(213,255,255,1) 0%, rgba(104,168,255,1) 65%, rgba(80,126,243,1) 76%, rgba(80,126,243,1) 82%, rgba(63,69,204,1) 99%);

}



.sp{
  background-image: url('../../assets/images/slantedPillers.png');
  background-size: cover;
}

